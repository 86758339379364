import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, ReferencesBlock, IconDefinitionList, FeaturedResource } from '../../components';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Biomarker Testing | KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `biomarker testing, biomarker overview`,
    description: `Health care professionals may learn more about the role of biomarker testing in a patient’s prognosis and help determine potential treatment options.`,
    schemaJsonLD: [
        ` {"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/biomarker-overview/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals."} `,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/biomarker-overview/","@type":"MedicalWebPage","name":"The Role of Biomarker Testing","description":"Scientific advances over the years have identified certain molecules that are contributors to the development of cancer. Referred to as biomarkers, these molecules can be measured to gain insights into a patient’s prognosis and/or determine potential treatment options. As a result, the treatment of many cancer types has made strides toward a more personalized approach for individual patient care."} `,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/biomarker-overview/","@type":"MedicalWebPage","name":"Reflex Testing","description":"Appropriate reflex testing may help avoid delays in obtaining certain test results, potentially supporting more timely selection of appropriate treatment."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/biomarker-testing/biomarker-overview/","@type":"MedicalWebPage","name":"Pathology Reports","description":"Biomarker testing should be ordered at diagnosis, when recommended by clinical practice guidelines.4 Complete and timely lab reports may help with personalizing treatment plans for certain patients who may be eligible for immunotherapy."}`
    ]
}

const jobCode = jobCodes[27].jobCode;

const relatedContent = [
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/pd-l1/',
            label: 'PD&#8288;-&#8288;L1 Testing & Scoring'
        }
    },
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/msi-mmr/',
            label: 'MSI/MMR Testing'
        }
    }
]

const pageReferences = [
    {
        label: "1.",
        text: "Kamel HFM, Al-Amodi HSAB. Exploitation of gene expression and cancer biomarkers in paving the path to era of personalized medicine. <em>Genomics Proteomics Bioinformatics</em>. 2017;15(4):220–235. doi:10.1016/j.gpb.2016.11.005"
    },
    {
        label: "2.",
        text: "Sepulveda AR, Hamilton SR, Allegra CJ, et al. Molecular biomarkers for the evaluation of colorectal cancer: guideline from the American Society for Clinical Pathology, College of American Pathologists, Association for Molecular Pathology, and the American Society of Clinical Oncology. <em>J Clin Oncol</em>. 2017;35(13):1453–1486. doi:10.1200/JCO.2016.71.9807"
    },
    {
        label: "3.",
        text: "Gregg JP, Li T, Yoneda KY. Molecular testing strategies in non-small cell lung cancer: optimizing the diagnostic journey. <em>Transl Lung Cancer Res</em>. 2019;8(3):286–301. doi:10.21037tlcr.2019.04.14"
    },
    {
        label: "4.",
        text: "Pennell NA, Arcila ME, Gandara DR, et al. Biomarker testing for patients with advanced non–small cell lung cancer: real-world issues and tough choices. <em>Am Soc Clin Oncol Educ Book</em>. 2019;39:531–542. doi:10.1200/EDBK_23786"
    },
    {
        label: "5.",
        text: "Cree IA, Deans Z, Ligtenberg MJL, et al. Guidance for laboratories performing molecular pathology for cancer patients. <em>J Clin Pathol</em>. 2014;67(11):923–931. doi:10.1136/jclinpath-2014-202404"
    },
    {
        label: "6.",
        text: "Anand K, Phung TL, Bernicker EH, et al. Clinical utility of reflex ordered testing for molecular biomarkers in lung adenocarcinoma. <em>Clin Lung Cancer</em>. 2020;21(5):437–442. doi:10.1016/j.cllc.2020.05.007"
    },
    {
        label: "7.",
        text: "Agilent Technologies, Inc. Instructions for Use: PD&#8288;-&#8288;L1 IHC 22C3 pharmDx."
    },
    {
        label: "8.",
        text: "Kim S-W, Roh J, Park C-S. Immunohistochemistry for pathologists: protocols, pitfalls, and tips. <em>J Pathol Transl Med</em>. 2016;50(6):411–418. doi:10.4132/jptm.2016.08.08"
    },
    {
        label: "9.",
        text: "Selby P, Popescu R, Lawler M, et al. The value and future developments of multidisciplinary team cancer care. <em>Am Soc Clin Oncol Educ Book</em>. 2019;39:332–340. doi:10.1200/edbk_236857"
    },
    {
        label: "10.",
        text: "Hirsch FR, Kerr KM, Bunn PA Jr, et al. Molecular and immune biomarker testing in squamous-cell lung cancer: effect of current and future therapies and technologies. <em>lin Lung Cancer</em>. 2018;19(4):331–339. doi:10.1016/j.cllc.2018.03.014"
    },
    {
        label: "11.",
        text: "Garrido P, Conde E, de Castro J, et al. Updated guidelines for predictive biomarker testing in advanced non-small-cell lung cancer: a National Consensus of the Spanish Society of Pathology and the Spanish Society of Medical Oncology. <em>Clin Transl Oncol</em>. 2020;22(7):989–1003. doi:10.1007/s12094-019-02218-4"
    },
    {
        label: "12.",
        text: "Association of Community Cancer Centers. <em>Integration of Pathology in Oncology Care: Leadership Summit Proceedings</em>. Association of Community Cancer Centers; 2018. Accessed September 7, 2023. https://www.accc-cancer.org/docs/projects/landscape-of-pathology/pathology-summit-proceedings-final.pdf?sfvrsn=8e59b849_2"
    },
    {
        label: "13.",
        text: "Winters DA, Soukup T, Sevdalis N, et al. The cancer multidisciplinary team meeting: in need of change? History, challenges and future perspectives. <em>BJU Int</em>. 2021;128(3):271–279. doi:10.1111/bju.15495"
    },
    {
        label: "14.",
        text: "PD&#8288;-&#8288;L1 IHC 22C3 pharmDx Interpretation Manual – Cervical Cancer. Agilent Technologies, Inc. Published January 10, 2019. Accessed June 18, 2021. https://www.agilent.com/cs/library/usermanuals/public/29257_22c3_pharmDx_cervical_interpretation_manual_us.pdf"
    },
    {
        label: "15.",
        text: "Agilent Technologies, Inc. PD&#8288;-&#8288;L1 IHC 22C3 pharmDx Interpretation Manual—Gastric or Gastroesophageal Junction Adenocarcinoma. Santa Clara, CA: Agilent Technologies, Inc.; July 2019."
    },
    {
        label: "16.",
        text: "PD&#8288;-&#8288;L1 IHC 22C3 pharmDx Interpretation Manual—Triple-Negative Breast Cancer (TNBC). Agilent Technologies, Inc. Published November 2020.  https://www.agilent.com/cs/library/usermanuals/public/29389_22c3_pharmdx_tnbc_interpretation_manual_kn355.pdf"
    },
    {
        label: "17.",
        text: "PD&#8288;-&#8288;L1 IHC 22C3 pharmDx interpretation manual - esophageal squamous cell carcinoma (ESCC). Agilent Technologies, Inc. Published February 7, 2022."
    },
    {
        label: "18.",
        text: "PD&#8288;-&#8288;L1 IHC 22C3 PharmDx Interpretation Manual – Head and Neck Squamous Cell Carcinoma (HNSCC). Agilent Technologies, Inc. Published June 11, 2019. https://www.agilent.com/cs/library/usermanuals/public/29314_22c3_pharmDx_hnscc_interpretation_manual_us.pdf"
    },
    {
        label: "19.",
        text: "PD&#8288;-&#8288;L1 IHC 22C3 PharmDx Interpretation Manual – Non-small Cell Lung Cancer (NSCLC). Agilent Technologies, Inc. Published April 18, 2019. https://www.agilent.com/cs/library/usermanuals/public/29158_pd-l1-ihc-22C3-pharmdx-nsclc-interpretation-manual.pdf"
    },
]

const resourceData = [
    {
        iconColumn: {
            icon: '/static/images/badge-microscope-slide.png',
            heading: 'The Role of Biomarker Testing',
            alt: ''
        },
        contentMarkup: `<p>Scientific advances over the years have identified certain molecules that are contributors to the development of cancer. Referred to as <strong>biomarkers</strong>, these molecules can be <strong>measured to gain insights</strong> into a patient’s prognosis and/or <strong>determine potential treatment options</strong>. As a result, the treatment of many cancer types has made strides toward a more personalized approach for individual patient care.<sup>1</sup></p>`,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-clock.png',
            heading: 'Reflex Testing',
            alt: ''
        },
        contentMarkup: `<p>Appropriate reflex testing may help avoid delays in obtaining certain test results, potentially supporting more timely selection of appropriate treatment.<sup>2–6</sup> The nature of reflex testing may also:</p>
                            <ul>
                                <li>Reduce the need for retrieval of tissue at a later date</li>
                                <li>Lead to improved test turnaround times</li>
                                <li>Be more cost-efficient, especially at institutions where >10% of patients with a given diagnosis require testing</li>                
                            </ul>
                        <p>Discuss incorporation of reflex testing with your MDT for appropriate biomarkers.<sup>5</sup></p>`, 
    },
    {
        iconColumn: {
            icon: '/static/images/badge-checkmark.png',
            heading: 'Quality Practices',
            alt: ''
        },
        contentMarkup: `<p>When testing for biomarkers, <strong>quality standards may impact test results</strong>.<sup>7</sup></p>
            <p>Consider available guidelines and manufacturer recommendations, as appropriate, for tissue specimen processing and preparation within your MDT.<sup>7,8</sup></p>
            <p>Collaborate with your MDT about applying these practices across all stages of testing, <strong>from preanalytics to scoring and reporting</strong>.</p>
        `,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-molecules.png',
            heading: 'Pathologist Involvement in the MDT',
            alt: ''
        },
        contentMarkup: `<p>Biomarker testing is a complex process that <strong>requires cooperation</strong> throughout the MDT.<sup>9,10</sup> </p>
        <ul>
            <li>All members of an MDT have distinct roles in the testing and treatment process.<sup>3,10,11</sup> </li>
            <li>Although the pathologist may not interact with patients regularly, they play a <strong>vital role in biomarker testing</strong>, which may help inform the patients’ diagnostic process.<sup>10,12</sup></li>
        </ul>
        <p>Through collaboration with all members of the MDT, <strong>pathologists can help inform patient care</strong>.<sup>13</sup></p>`,
    },
    {
        iconColumn: {
            icon: '/static/images/badge-clipboard.png',
            heading: 'Pathology Reports',
            alt: ''
        },
        contentMarkup: `<p>Pathology reports may help <strong>inform decisions about treatment</strong> with immunotherapy.<sup>2,4</sup> </p>
        <p>Biomarker testing should be ordered <strong>at diagnosis</strong>, when recommended by clinical practice guidelines.<sup>4</sup> Complete and timely lab reports may help with personalizing treatment plans for certain patients who may be eligible for immunotherapy.<sup>2</sup></p>
        <p>When testing appropriate patients for biomarker status, some <strong>FDA-approved companion diagnostics suggest including the relevant indications for immunotherapy</strong> in the comments section for the treating physician.<sup>14–19</sup></p>`,
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode} >
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro" maxWidth={824}>
                    <PageHeader title="Biomarker Overview" />
                </TemplateColumn>
                {/* End Intro Column */}

                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection>
                        <IconDefinitionList data={resourceData} theme="light" definitions="MDT = multidisciplinary team; FDA = US Food and Drug Administration."/>
                    </PageSection>
                    <PageSection bgColor="cloud">
                        <FeaturedResource title="Biomarker Eligibility Brochure" description="A guide to biomarker testing and cut points for relevant tumor types and indications." ctas={[
                            {
                                label: 'Download PDF',
                                url: 'https://www.keytrudahcp.com/static/pdf/HCP_Biomarker_Eligibility_Brochure.pdf'
                            }
                        ]}/>
                    </PageSection>

                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;
